<script setup lang="ts">
import { EyeSlashIcon, PencilIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  error: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  label: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  name: {
    type: String,
    default: () => Math.random().toString(36).substring(2, 15),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
  testId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "blur"]);

watch(props, () => {
  // console.log(props.modelValue);
  if (props.type === "url") {
    // value.value longer than 8 and doesn't include http or https add https
    if (
      props.modelValue.split("").includes(".") &&
      !props.modelValue.includes("http") &&
      !props.modelValue.includes("https")
    ) {
      emit("update:modelValue", `https://${props.modelValue}`);
    }

    // remove spaces
    if (props.modelValue.includes(" ")) {
      emit("update:modelValue", props.modelValue.replace(/\s/g, ""));
    }
  }
});

const passwordOverride = ref(false);
</script>

<template>
  <div class="input-grid group h-fit w-full">
    <label
      class="grid-label block text-xs leading-6 text-input-label text-opacity-80"
      :for="props.label"
    >
      {{ props.label }}
    </label>
    <div class="grid-left mr-3" :class="{ hidden: !$slots.left }">
      <slot name="left" />
    </div>
    <div
      class="grid-center inline-flex w-full items-center gap-x-3 rounded-xl bg-white ring-1 ring-inset"
      :class="[
        props.disabled
          ? 'ring-input-disabled'
          : error.length > 0
          ? 'ring-red-500'
          : 'ring-input focus-within:ring-2 focus-within:ring-input-focus group-hover:ring-input-hover',
      ]"
    >
      <div class="relative grow">
        <input
          :id="props.label"
          class="w-full truncate rounded-xl bg-transparent px-5 py-3 text-sm font-normal outline-none placeholder:text-input-hover disabled:cursor-not-allowed disabled:text-default-text disabled:text-opacity-30"
          :class="[props.hideIcon ? '' : 'pr-10']"
          :value="modelValue"
          :type="passwordOverride ? 'text' : props.type"
          :name="props.label"
          :disabled="props.disabled"
          :autofocus="props.autofocus"
          :placeholder="props.placeholder"
          :data-testid="props.testId + '-input'"
          @input="
            [
              emit('update:modelValue', $event.target?.value),
              (passwordOverride = false),
            ]
          "
          @blur="emit('blur')"
        />
        <template v-if="props.type === 'password'">
          <div
            v-show="!props.hideIcon"
            class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
            @click="passwordOverride = !passwordOverride"
          >
            <EyeSlashIcon
              :class="[
                'h-4 w-4',
                props.disabled
                  ? 'fill-input-disabled'
                  : 'fill-input-misc group-hover:fill-input-misc-hover',
              ]"
              aria-hidden="true"
            />
          </div>
        </template>
        <template v-else>
          <div
            v-show="!props.hideIcon"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <PencilIcon
              :class="[
                'h-4 w-4',
                props.disabled
                  ? 'fill-input-disabled'
                  : 'fill-input-misc group-hover:fill-input-misc-hover',
              ]"
              aria-hidden="true"
            />
          </div>
        </template>
      </div>
      <slot name="inside" />
    </div>
    <div class="grid-right ml-3" :class="{ hidden: !$slots.right }">
      <slot name="right" />
    </div>
    <div class="grid-bottom">
      <p
        v-if="error.length"
        class="whitespace-pre-wrap text-sm text-red-500"
        :data-testid="props.testId + '-error'"
      >
        {{ error[0] }}
      </p>
      <slot v-else name="bottom" />
    </div>
  </div>
</template>

<style scoped>
.input-grid {
  display: grid;

  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 44px auto;
  grid-template-areas: ". label ." "left center right" ". bottom .";
  /* gap: 0px; */
  @apply gap-y-1;
}

.grid-label {
  grid-area: label;
}

.grid-left {
  grid-area: left;
}

.grid-center {
  grid-area: center;
}

.grid-right {
  grid-area: right;
}

.grid-bottom {
  grid-area: bottom;
}
</style>
